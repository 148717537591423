
.result_scorebox_col{
    max-width: 502px;
    position: relative;
    border: 1px solid #ccc;
    padding: 22px;
    height: 318px;
    margin: 10px;
    /* margin: auto; */
    border-radius: 15px;
    max-height: 313px;
}
.top_content{
    display: flex;
    align-items: center;
    position: relative;
}
.scorebox_title {
    /* text-align: left; */
    width: calc(100% - 102px);
}
.result_skill_box {
    /* display: inline-block;
    vertical-align: top;
    padding-top: 40px; */
    width: 100px;
}

.result_scorebox_col .scorebox_title h4 {
    font-weight: 600;
    font-size: 24px;
    color: #000000;
    margin-bottom: 12px;
    line-height: 30px;
    word-break: break-word;
}
.result_scorebox_col h4 strong {
    font-size: 64px;
    display: block;
    font-weight: 600;
    line-height: 100%;
    margin-bottom: 15px;
  }
.scorebox_title small {
    display: block;
    font-weight: 600;
    font-size: 12px;
}
.scorebox_title label {
    font-size: 14px;
    color: #000;
    font-weight: normal;
}
 span.btm_content{
    margin: 20px 0 0 0;
    text-align: left;
    font-weight: 600;
}
span.btm_content small {
    font-weight: normal;
}
.pr_circile {
    width: 100px;
}